import { Feather } from '@expo/vector-icons';
import { Text } from '_/components';
import OfficeAvatar from '_/components/OfficeAvatar';
import { fontSizes } from '_/constants/theme';
import { useLocationContext } from '_/hooks/LocationContext';
import { useSlotContext } from '_/hooks/SlotContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { View, TouchableOpacity } from 'react-native';

import OfficeModal from './OfficeModal';
import { SpacesProps } from './OfficeModal/SpacesCard';
import styles from './styles';
import { endOfDay, startOfDay } from 'date-fns';

interface OfficeProps {
  locationName: string;
  locationId: string;
  locationAvatar: string | undefined;
}

export default function Office({ locationId, locationName, locationAvatar }: OfficeProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const { locations } = useLocationContext();
  const [spacesData, setSpacesData] = useState<SpacesProps[]>([]);
  const { getSpacesbyLocationId } = useSpaceContext();
  const { getAvailableSlotsbySpaceId } = useSlotContext();
  const { t } = useTranslation();

  const [availableSlotsbySpaceId, setAvailableSlotsbySpaceId] = useState(false);

  const toggleModalVisibility = () => {
    setModalVisible(!modalVisible);
  };

  useEffect(() => {
    if (spacesData.length) {
      setSpacesData([]);
    }

    async function getSpaces() {
      const data = await getSpacesbyLocationId({ locationId });

      data.forEach(async (space) => {
        let availableSlotsCount = t('noneAvailable');
        const availableSlot = await getAvailableSlotsbySpaceId(
          space.id,
          startOfDay(new Date()),
          endOfDay(new Date())
        );

        if (availableSlot.length > 0) {
          setAvailableSlotsbySpaceId(true);

          if (availableSlot?.length >= 100) {
            availableSlotsCount = `+${t('available', { count: 99 })}`;
          } else {
            availableSlotsCount = t('available', { count: availableSlot.length });
          }
          setSpacesData((prev) =>
            [...prev, { ...space, availableSlotsCount }].sort((a, b) =>
              a.name.localeCompare(b.name)
            )
          );
        }
      });
    }

    getSpaces();
  }, [locationId, locations]);

  if (!availableSlotsbySpaceId) {
    return null;
  }

  return (
    <>
      <TouchableOpacity onPress={toggleModalVisibility} disabled={locations.data.length === 1}>
        <View style={styles.officeContainer}>
          <View style={styles.infoContainer}>
            <OfficeAvatar officeName={locationName} source={locationAvatar} />
            <Text fontSize={fontSizes.md2} fontType="bold" numberOfLines={1}>
              {locationName}
            </Text>
          </View>

          {!modalVisible ? (
            <Feather
              name="chevron-down"
              size={20}
              style={{ display: locations.data.length === 1 ? 'none' : 'flex' }}
            />
          ) : (
            <Feather name="chevron-up" size={20} />
          )}
        </View>
      </TouchableOpacity>
      <OfficeModal
        visible={modalVisible}
        locationId={locationId}
        locationName={locationName}
        spacesData={spacesData}
      />
    </>
  );
}
